/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import cityMetaData from "cms/city-meta-data";
import ExternalLinkIcon from "assets/svgs/ExternalLinkIcon";
import {
	LOCATION_JOBS_PATTERN,
	WOMEN_JOBS_PATTERN,
	WOMAN_JOBS_NEW_PATTERN,
	CATEGORY_JOBS_PATTERN,
	INDUSTRY_MAP,
	EDUCATION_MAP,
	JOB_LOCATION_TYPE,
} from "./constants";

export const getUrlPath = () => typeof window !== "undefined" && window.location.pathname;

export const slugify = (data = "") => {
	let urlSlug = data;
	if (data.includes("ncr")) {
		urlSlug = "delhi_ncr";
	}
	return urlSlug.replace(/[^A-Z0-9]+/gi, "_").toLowerCase();
};

export const slugifyDepartment = (str) =>
	str.replace(/[^a-zA-Z0-9]+/g, "_").toLowerCase();

export const getJobSchema = ({ job, categoryData, jobDescriptionTemplate }) => {
	const {
		id,
		title,
		description,
		expiry,
		is_part_time,
		organization,
		created_on,
		address,
		min_salary,
		max_salary,
		no_of_openings,
		education,
		category_slug,
		location_type,
		international_job_info,
		company_address,
	} = job;
	const city =
		location_type === JOB_LOCATION_TYPE.FIELD_LOCATION
			? company_address?.area?.city
			: address?.city;
	const cityMeta = cityMetaData[slugify(city?.name)];

	const industry = categoryData.find((c) => c.slug === category_slug);
	const line_1 =
		location_type === JOB_LOCATION_TYPE.FIELD_LOCATION
			? company_address?.line_1
			: address?.line_1;
	const streetAddress = line_1?.replace(/"/g, "'") || "";
	const areaName =
		location_type === JOB_LOCATION_TYPE.FIELD_LOCATION
			? company_address?.area?.name
			: address?.area;
	const addressLocality = `${areaName}, ${city?.name}` || "";

	const alphaNumericRegex = /d*[a-zA-Z0-9][a-zA-Z0-9]*/;

	const isAddressDataPresent =
		streetAddress.match(alphaNumericRegex) &&
		addressLocality.match(alphaNumericRegex);

	if (!isAddressDataPresent && location_type !== JOB_LOCATION_TYPE.WFH) {
		return null;
	}

	const jobDescription = `<p>
	${job.description}
	\n
	More about this ${job?.title} job
	\n
	${jobDescriptionTemplate.replace(/<strong>/g, "").replace(/<\/strong>/g, "")}
	</p>`.replace(/\t/g, "");

	const data = {
		"@context": "https://schema.org/",
		"@type": "JobPosting",
		title,
		description: jobDescription || `<p>${description?.replace(/"/g, "'")}</p>`,
		datePosted: created_on,
		validThrough: expiry ? new Date(expiry)?.toISOString() : "",
		employmentType: is_part_time ? "PART_TIME" : "FULL_TIME",
		hiringOrganization: {
			"@type": "Organization",
			name: organization?.name,
			logo: organization?.logo_url || "https://apna.co/apna-time-icon.png",
		},
		identifier: {
			"@type": "PropertyValue",
			name: organization?.name,
			value: id,
		},
		applicantLocationRequirements: {
			"@type": "Country",
			name: international_job_info?.international_job_country
				? international_job_info?.international_job_country
				: "India",
		},
		...(location_type !== JOB_LOCATION_TYPE.WFH && {
			jobLocation: {
				"@type": "Place",
				address: {
					"@type": "PostalAddress",
					streetAddress,
					addressLocality,
					addressRegion: `${cityMeta?.regionName}`,
					postalCode: `${cityMeta?.pincode}`,
					addressCountry: "IN",
				},
			},
		}),
		baseSalary: {
			"@type": "MonetaryAmount",
			currency: "INR",
			value: {
				"@type": "QuantitativeValue",
				minValue: parseInt(min_salary, 10),
				maxValue: parseInt(max_salary, 10),
				unitText: "MONTH",
			},
		},
		totalJobOpenings: no_of_openings,
		educationRequirements: {
			"@type": "EducationalOccupationalCredential",
			credentialCategory: EDUCATION_MAP[education]
				? EDUCATION_MAP[education]
				: "High School",
		},
		industry: industry ? INDUSTRY_MAP[industry.type] || "Private Jobs" : "",
		...(location_type === JOB_LOCATION_TYPE.WFH && {
			jobLocationType: "TELECOMMUTE",
		}),
	};
	return JSON.stringify(data);
};
export const getFAQSchema = ({ jobDescriptionTemplate }) => {
	const faqsArray = [];
	let data = {};
	let faqsSchema = "";
	try {
		let faqsContent = jobDescriptionTemplate.slice(
			jobDescriptionTemplate.indexOf("<strong>")
		);

		const extractFAQ = (allFAQs) => {
			// Get the first question from the FAQs string.
			// Length of tag <strong> is 8 + length of question number "1. " prefix is 3 = 11
			const faqQuestion = allFAQs.slice(
				allFAQs.indexOf("<strong>") + 11,
				allFAQs.indexOf("</strong>")
			);
			// Remove the question and store resulting string in a new variable.
			// Length of tag </strong> is 9.
			const faqsContentQuestionRemoved = allFAQs
				.slice(allFAQs.indexOf("</strong>") + 9)
				.trim();
			// Get the answer to the question removed from the string.
			// Length of prefix "Ans. " is 5.
			const faqAnswer = faqsContentQuestionRemoved
				.slice(5, faqsContentQuestionRemoved.indexOf("<strong>"))
				.trim();
			// Remove the answer and store the resulting string in a new variable.
			const faqsContentAnswerRemoved = faqsContentQuestionRemoved
				.slice(faqsContentQuestionRemoved.indexOf("<strong>"))
				.trim();
			// Assign resulting string to paramter passed into the extractFAQ function
			// eslint-disable-next-line no-param-reassign
			allFAQs = faqsContentAnswerRemoved;
			// Assign resulting string to original value passed in to track the operation of the while loop.
			faqsContent = allFAQs;

			// Store Q and A in an object.
			const faq = {
				question: faqQuestion,
				answer: faqAnswer,
			};
			// Push object into array of FAQs.
			faqsArray.push(faq);
		};
		// Run the function till the all faqs are removed and pushed into FAQs array.
		while (faqsContent.length > 1) {
			extractFAQ(faqsContent);
		}
		// Generate value for FAQPage schema mainEntity property.
		faqsSchema = faqsArray.map((faq) => ({
			"@type": "Question",
			name: `${faq.question}`,
			acceptedAnswer: {
				"@type": "Answer",
				text: `${faq.answer}`,
			},
		}));
	} catch (e) {
		faqsSchema = null;
	} finally {
		if (faqsSchema !== null) {
			data = {
				"@context": "https://schema.org",
				"@type": "FAQPage",
				mainEntity: faqsSchema,
			};
		}
		if (faqsSchema === null) {
			data = null;
		}
	}
	if (data !== null) {
		return JSON.stringify(data);
	}
	return null;
};

// breadcrumbs
export const jobRootBreadcrumb = {
	name: "jobs",
	path: "/jobs",
};

export const jobCityAreaBreadcrumb = ({ city, area }) => {
	if (!area) {
		return {
			...jobRootBreadcrumb,
			child: {
				name: city?.name,
				path: `/jobs/${LOCATION_JOBS_PATTERN}-${city?.slug}`,
			},
		};
	}

	return {
		...jobRootBreadcrumb,
		child: {
			name: city?.name,
			path: `/jobs/${LOCATION_JOBS_PATTERN}-${city?.slug}`,
			child: {
				name: area?.name.toLowerCase(),
				path: `/jobs/${LOCATION_JOBS_PATTERN}-${area?.slug}-${city?.slug}`,
			},
		},
	};
};

export const jobCategoryBreadcrumb = ({ category, city, area }) => {
	const categoryBreadcrumb = {
		...jobRootBreadcrumb,
		child: {
			name: category.type,
			path: `/jobs/${CATEGORY_JOBS_PATTERN}-${category.slug}`,
		},
	};
	if (city?.slug) {
		const cityCategoryBreadcrumb = {
			...categoryBreadcrumb,
			child: {
				...categoryBreadcrumb.child,
				child: {
					name: city.name,
					path: `/jobs/${CATEGORY_JOBS_PATTERN}-${category.slug}-in-${city.slug}`,
				},
			},
		};
		if (area?.id) {
			return {
				...cityCategoryBreadcrumb,
				child: {
					...cityCategoryBreadcrumb.child,
					child: {
						...cityCategoryBreadcrumb.child.child,
						child: {
							name: area.name,
							path: `/jobs/${CATEGORY_JOBS_PATTERN}-${category.slug}-in-${area.slug}-${city.slug}`,
						},
					},
				},
			};
		}
		return cityCategoryBreadcrumb;
	}
	return categoryBreadcrumb;
};

export const otherBreadcrumb = ({ city, baseDisplayText, pattern }) => {
	const breadcrumbData = {
		...jobRootBreadcrumb,
		child: {
			name: baseDisplayText,
			path: `/jobs/${pattern}`,
		},
	};
	if (city?.slug) {
		return {
			...breadcrumbData,
			child: {
				...breadcrumbData.child,
				child: {
					name: city.name.toLowerCase(),
					path: `/jobs/${pattern}-in-${city.slug}`,
				},
			},
		};
	}
	return breadcrumbData;
};

export const womenJobBreadcrumb = ({ city }) => {
	const womenBreadcrumb = {
		...jobRootBreadcrumb,
		child: {
			name: "women",
			path: `/jobs/${WOMEN_JOBS_PATTERN}`,
		},
	};
	if (city?.slug) {
		return {
			...womenBreadcrumb,
			child: {
				...womenBreadcrumb.child,
				name: "female",
				child: {
					name: city.name,
					path: `/jobs/${WOMAN_JOBS_NEW_PATTERN}-in-${city.slug}`,
				},
			},
		};
	}
	return womenBreadcrumb;
};

const capitalise = (word) => {
	const prepositions = ["in", "for"];
	if (prepositions?.includes(word)) return word;
	// exception if word is ncr then capitalise the complete word
	if (word?.toLowerCase() === "ncr") return word.toUpperCase();
	if (word?.[0] === "(") return `(${word?.[1]?.toUpperCase()}${word?.slice(2)}`;
	// eslint-disable-next-line no-unsafe-optional-chaining
	return word?.[0].toUpperCase() + word?.slice(1);
};

export const capitaliseWords = (sentence) => {
	const words = sentence.split(" ");
	const parsedWords = words?.map((word) => {
		if (word.includes("-")) {
			const wordList = word.split("-");
			return wordList?.map((anotherWord) => capitalise(anotherWord))?.join("-");
		}
		return capitalise(word);
	});

	return parsedWords?.join(" ");
};

// Returns string with first letter capital. Ex. abc->Abc
export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const buildBreadcrumbSchema = (data, count) => {
	if (!data.child)
		return [
			{
				"@type": "ListItem",
				position: count,
				name: capitalise(data.name),
			},
		];
	return [
		{
			"@type": "ListItem",
			position: count,
			name: capitalise(data.name),
			item: `https://apna.co${data.path}`,
		},
		...buildBreadcrumbSchema(data.child, count + 1),
	];
};

export const isWebBrowser = () => typeof window !== "undefined";

export const getScreenNameFromRoute = (path) => {
	if (path.includes("/jobs/")) {
		return "Job list page";
	}
	if (path.includes("/job/")) {
		return "Job detail page";
	}
	if (path === "/") {
		return "Home page";
	}
	return null;
};

export const copyTextClipboard = async (value) => {
	if (navigator && navigator?.clipboard && navigator?.clipboard?.writeText) {
		await navigator.clipboard.writeText(value);
	} else {
		const inputEle = document.createElement("input");
		inputEle.setAttribute("value", value);
		document.body.appendChild(inputEle);
		inputEle.select();
		document.execCommand("copy");
		inputEle.parentNode.removeChild(inputEle);
	}
};

export const getHash = () => {
	const URLObject = new URL(window.location.href);
	return URLObject.hash;
};

export const removeHash = () => {
	// eslint-disable-next-line no-var
	var uri = window.location.toString();

	if (uri.indexOf("#") > 0) {
		// eslint-disable-next-line no-restricted-globals
		history.back();
	}
};

export const externalJobApplyCTA = (jobDetails) => {
	if (jobDetails?.apply_job_cta_section_config?.job_info?.type === "social_form")
		return "Complete Application Form";
	if (jobDetails?.apply_job_cta_section_config?.job_info?.type === "email")
		return "Apply via email";
	return (
		<p className="m-0 flex flex-row items-center gap-[4px] whitespace-nowrap text-center text-sm">
			Apply on company website
			<ExternalLinkIcon />
		</p>
	);
};

export const autoReadSMS = (cb) => {
	const ac = new AbortController();

	async function main() {
		try {
			if (navigator.credentials) {
				try {
					navigator.credentials
						.get({
							otp: { transport: ["sms"] },
							signal: ac.signal,
						})
						.then((otp) => {
							if (otp && otp.code) {
								cb(otp.code);
							}
						})
						.catch((e) => console.log(e));
				} catch (e) {
					ac.abort();
				}
			}
		} catch (err) {
			ac.abort();
			console.log(err);
		}
	}

	main();
	return ac;
};
