import { FC, useEffect } from "react";
import OTPInput from "react-otp-input";
import { autoReadSMS } from "utils/helpers";
import { useWindowResize } from "utils/hooks";

type Props = {
	handleInputChange: (event: string) => void;
	otpValue: string;
	isOtpError: boolean;
};

const OtpInput: FC<Props> = ({ handleInputChange, otpValue, isOtpError = false }) => {
	const [isMobileView] = useWindowResize();

	useEffect(() => {
		let ac;
		if (isMobileView)
			ac = autoReadSMS((otp) => {
				handleInputChange(otp);
			});
		return () => {
			ac?.abort();
		};
	}, []);
	return (
		<OTPInput
			onChange={handleInputChange}
			value={otpValue}
			numInputs={4}
			containerStyle="otp-input-container-styles"
			inputStyle={isOtpError ? "otp-input-styles-error" : "otp-input-styles"}
			shouldAutoFocus
			renderInput={(props) => (
				<input
					type="text"
					inputMode="numeric"
					autoComplete="one-time-code"
					{...props}
				/>
			)}
		/>
	);
};
export default OtpInput;
